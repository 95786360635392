export const appStrings = {
  error: 'Some error occurred. Please try again',
  maintenance: {
    message: 'This feature is currently \n under maintenance.',
    button1Name: 'Okay, got it!',
  },
  myProject: {
    accessDenied: 'Access denied due to role restriction',
  },
  create: {
    heading: 'Create feature, what will it serve you?',
    subtext:
      'You’ll be able to create actions, escalations, issues and much more with just one single click. Hold on, it’s coming soon!',
  },
  writeToUs: {
    heading: 'Write an email to us, what will it serve you?',
    subtext:
      'You’ll be able to send email to us directly and much more with just one single click. Hold on, it’s coming soon!',
  },
  people: {
    heading: 'People details, what will it serve you?',
    subtext:
      'You’ll be able to view actions, escalations, issues and much more related to the selected person with just one single click. Hold on, it’s coming soon!',
  },
  notification: {
    heading: 'Notifications, what will it serve you?',
    subtext:
      'You’ll be gaining insights on your progress, performance, and updates on your activity log. Hold on, it’s coming soon!',
  },
  emptyComingSoon: {
    heading: '',
    subtext: '',
  },
  rightStart: {
    heading: 'Right Start, what will it serve you?',
    subtext:
      'You’ll be gaining insights on your progress, performance, and updates on your activity log. Hold on, it’s coming soon!',
  },
  codexDashboard: {
    title: 'codeXDashboard',
    heading: '',
    subtext: 'For NWOW Dashboard, Please Click on the below link',
    url: `https://nwow.brillio.com/`,
  },
  auth: {
    summary: {
      title: 'CODEX 4.0 | Login',
      message: 'Login to the application',
    },
    session: 'Session Timeout. Please login again.',
    buttons: { logout: 'Logout' },
    logout: 'See you soon!!! {#{#}#}',
  },
  whatsNew: {
    errors: {
      fetch:
        'Some Error occured while fetching new Features. Please try again.',
    },
  },
  welcome: {
    summary: {
      title: 'CODEX 4.0 | Welcome',
      message: 'Welcome to CODEX',
    },
  },
  home: {
    summary: {
      title: 'CODEX 4.0 | HOME',
      message: 'Home Page of the application',
    },
  },

  myAccounts: {
    summary: {
      title: 'CODEX 4.0 | HOME | My Accounts',
      message: "List of all Accounts and it's summary.",
    },
    fetch: {
      heading: 'My Accounts Error',
      subtext: 'Some error occured while fetching your account list.',
    },
    nodata: {
      heading: 'No Accounts Found',
      subtext: 'No Accounts assigned for you. Please check with Codex Admin',
    },
    noSearchData: {
      heading: 'No Accounts Found',
      subtext: 'No results found for the given search query',
    },
  },
  accountDetails: {
    summary: {
      title: 'CODEX 4.0 | Account Details',
      message: 'Detailed summary of the selected Account',
    },
  },

  myprojects: {
    summary: {
      title: 'CODEX 4.0 | HOME | My Projects',
      message: "List of all projects and it's summary.",
    },
    fetchCount: {
      heading: 'My Accounts Count Error',
      subtext: 'Some error occured while fetching your account list count.',
    },
    nodata: {
      heading: 'No Projects Found',
      subtext: 'No Projects assigned for you. Please check with Codex Admin',
    },
    noSearchData: {
      heading: 'No Projects Found',
      subtext: 'No results found for the given search query',
    },
    fetch: {
      heading: 'My Projects Error',
      subtext: 'Some error occured while fetching your project list.',
    },
  },
  projectDetails: {
    summary: {
      title: 'CODEX 4.0 | Project Details',
      message: 'Detailed summary of the selected project',
    },
  },
  allActions: {
    summary: {
      title: 'CODEX 4.0 | All Actions',
      message: 'List of all Actions',
    },
    levelWise: {
      message: 'What type of action would you like to create?',
      button1Name: 'Project Level',
      button2Name: 'Account Level',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Actions for {#{#}#}',
    },
    noChanges: {
      button1Name: 'Yes, Proceed!',
      message: `No changes done!\nDo you want to proceed as it is?`,
      button3Name: 'No, Go Back',
    },
    nodata: {
      heading: 'No Action Found',
      subtext: 'No Action found for the selected filter.',
    },
    nodataProject: {
      heading: 'No Action Found',
      subtext: 'No Action found for {#{#}#}',
    },
    nodataComment: {
      heading: 'No Comments Yet!',
      subtext: 'Be the 1st one to Comment.',
    },
    nodataCommentDELA: {
      heading: 'No Comments Yet!',
      subtext: '',
    },
    success: {
      create: 'Action created successfully!',
      update: 'Action updated successfully!',
      close: 'Action closed successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Action.',
      update: 'Some error occurred while updating Action.',
      close: 'Some error occurred while closing Action.',
      getActions: 'Some error occurred while fetching actions for Action.',
      fetchAccounts:
        'Some error occurred while fetching account dropdown list.',
      fetchCsatYears: 'Some error occured while fetching the CSAT Years dropdown list.',
    },
    confirm: {
      button1Name: 'Yes, Proceed!',
      message: 'Are you sure you want to close the selected Action?',
      button3Name: 'No, Go Back',
    },
  },
  riskmanagement: {
    summary: {
      title: 'CODEX 4.0 | HOME | Risk management',
      message: "List of risks and it's details",
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Risk for {#{#}#}',
    },
    success: {
      create: 'Risk created successfully!',
      update: 'Risk updated successfully!',
      convert: 'Risk converted successfully!',
      close: 'Risk closed successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Risk.',
      update: 'Some error occurred while updating Risk.',
      convert: 'Some error occurred while converting Risk.',
      close: 'Some error occurred while closing Risk.',
      getActions: 'Some error occurred while fetching actions for Risk.',
    },
    nodata: {
      heading: 'No Risks Found',
      subtext: 'No Risks found for the selected filter.',
    },
    nodataProject: {
      heading: 'No Risks Found',
      subtext: 'No Risks found for {#{#}#}',
    },
    nochanges: `No changes done!\nDo you want to proceed as it is?`,
    nochangesBtn1: 'Yes, Proceed!',
    nochangesBtn2: 'No, Go Back',
    closeInfo:
      '{#{#}#} Open Actions detected. If you choose to proceed, all open actions will be closed.',
    button1Name: 'Project Level',
    button2Name: 'Account Level',
    message: 'What type of risk would you like to create?',
  },
  tasksToComplete: {
    error: {
      heading: 'Task to completed error',
      subtext: 'Some error occured while fetching tasks to complete data.',
    },
    nodata: {
      heading: 'You’re all caught up!',
      subtext: 'No pending tasks. All of your tasks are completed!',
    },
  },
  escalation: {
    summary: {
      title: 'CODEX 4.0 | All Escalations',
      message: 'List of all escalations',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Escalations for {#{#}#}',
    },
    button1Name: 'Project Level',
    button2Name: 'Account Level',
    message: 'What type of escalation would you like to create?',
    nochanges: `No changes done!\nDo you want to proceed as it is?`,
    nochangesBtn1: 'Yes, Proceed!',
    nochangesBtn2: 'No, Go Back',
    closeInfo:
      '{#{#}#} Open Actions detected. If you choose to proceed, all open actions will be closed.',
    nodata: {
      heading: 'No Escalation Found',
      subtext: 'No Escalation found for the selected filter.',
    },
    nodataProject: {
      heading: 'No Escalation Found',
      subtext: 'No Escalation found for {#{#}#}',
    },
    success: {
      create: 'Escalation created successfully!',
      createWarning: 'Early Warning Created Successfully!',
      createRCA: 'RCA created successfully!',
      update: 'Escalation updated successfully!',
      updateWarning: 'Early Warning updated Successfully!',
      updateRCA: 'RCA updated successfully!',
      close: 'Escalation closed successfully!',
      closeWarning: 'Early Warning Closed Successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Escalation.',
      createRCA: 'Some error occurred while creating RCA.',
      update: 'Some error occurred while updating Escalation.',
      updateWarning: 'Some error occurred while updating Early Warning.',
      updateRCA: 'Some error occurred while updating RCA.',
      close: 'Some error occurred while closing Escalation.',
      closeWarning: 'Some error occurred while closing Early Warning.',
      getActions: 'Some error occurred while fetching actions for escalation.',
      fetchAccounts:
        'Some error occurred while fetching account dropdown list.',
    },
  },
  projectInformation: {
    success: {
      definition: 'Project Definition saved successfully.',
      acceptance: 'Acceptance Criteria saved successfully.',
      addSoldMargin: 'Sold Margin saved successfully',
      addDeCoach: 'De coach saved successfully',
      addActualStartDate: 'Actual start date saved successfully',
      addEstimation: 'Estimation saved successfully',
      published: 'Project Information successfully published',
      fileUpload: 'file uploaded successfully',
    },
    error: {
      definition: 'Some error occured while saving Project Definition.',
      acceptance: 'Some error occured while saving Acceptance Criteria.',
      domain: 'Some error occured while deleting domain.',
      addDomain: 'Some error occured while adding domain.',
      addPurpose: 'Some error occured while adding new purpose.',
      addEstimation: 'Some error occured while adding estimation',
      addDeCoach: 'Some error occured while adding de coach.',
      addSoldMargin: 'Some error occured while adding sold margin.',
      addActualStartDate: 'Some error occured while adding  actual start date.',
      published: 'Some error occured while publishing project information',
      fileUpload: 'Some error occured while file upload',
    },
  },
  internalIssue: {
    summary: {
      title: 'CODEX 4.0 | All Internal Issue',
      message: 'List of all internal issue',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Internal Issue for {#{#}#}',
    },
    success: {
      create: 'Internal Issue created successfully!',
      update: 'Internal Issue updated successfully!',
      close: 'Internal Issue closed successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Internal Issue.',
      update: 'Some error occurred while updating Internal Issue.',
      close: 'Some error occurred while closing Internal Issue.',
      getActions:
        'Some error occurred while fetching actions for Internal Issue.',
      fetchAccounts:
        'Some error occurred while fetching account dropdown list.',
    },
    nodata: {
      heading: 'No Internal Issues found',
      subtext: 'No Internal Issues found for the selected filter.',
    },
    nodataProject: {
      heading: 'No Internal Issue found',
      subtext: 'No Internal Issue found for {#{#}#}',
    },
    nochanges: `No changes done!`,
    nochangesBtn1: 'Yes, Proceed!',
    nochangesBtn2: 'No, Go Back',
    button3Name: 'Close',
    button1Name: 'Project Level',
    button2Name: 'Account Level',
    message: 'What type of Issue would you like to create?',
    closeInfo:
      '{#{#}#} Open Actions detected. If you choose to proceed, all open actions will be closed.',
  },
  assumption: {
    summary: {
      title: 'CODEX 4.0 | All Assumptions',
      message: 'List of all Assumptions',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Assumptions for {#{#}#}',
    },
    validity:
      'Please unselect Valid/InValid Quick Filter to use Validity Section from here.',
    nochanges: `No changes done!\nDo you want to proceed as it is?`,
    nochangesBtn1: 'Yes, Proceed!',
    nochangesBtn2: 'No, Go Back',
    nodata: {
      heading: 'No Assumptions Found',
      subtext: 'No Assumptions found for the selected filter.',
    },
    nodataProject: {
      heading: 'No Assumptions Found',
      subtext: 'No Assumptions found for {#{#}#}',
    },
    success: {
      create: 'Assumptions created successfully!',
      update: 'Assumptions updated successfully!',
      close: 'Assumptions closed successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Assumptions.',
      update: 'Some error occurred while updating Assumptions.',
      close: 'Some error occurred while closing Assumptions.',
      getActions: 'Some error occurred while fetching actions for Assumptions.',
    },
  },
  linkProject: {
    summary: {
      title: 'CODEX 4.0 | Link Projects',
      message: 'Link NetSuite Entities with Codex Project',
    },
    formSummaryCreate: {
      title: 'CODEX 4.0 | Create Project',
      message: 'Create Codex Project for NetSuite Entity Link',
    },
    formSummaryEdit: {
      title: 'CODEX 4.0 | Edit Project',
      message: 'Create Codex Project for NetSuite Entity Link',
    },
    form: {
      invalidProject: 'Not a valid Project.',
    },
    addProjectNodata:
      'No Additional NetSuite entities are available for selected account.',
    oneEntity: 'Please select at least one NetSuite entity to proceed.',
    note: 'Note: For multiple projects linking, please select projects from the same account in order to proceed.',
    nodata: {
      heading: 'You’re all caught up!',
      subtext: 'No pending NetSuite entities available for linking!',
    },
  },
  search: {
    nodata: {
      heading: 'No Recent search found! Start searching',
      subtext: 'Select the relevant category to find appropriate results',
    },
    nodataProjects: {
      heading: 'No results found!',
      subtext: 'No Projects found for the given search query.',
    },
    nodataFeature: {
      heading: 'No results found!',
      subtext: 'No Features found for the given search query.',
    },
    nodataPeople: {
      heading: 'No results found!',
      subtext: 'No People found for the given search query.',
    },
    nodataSearch: {
      heading: 'No results found!',
      subtext: 'No results found for the given search query.',
    },
    error: {
      people: 'Some error occured while fetching the People data.',
    },
  },
  form: {
    noresult: 'No Result Found',
    wait: 'Please wait...',
    required: 'Please fill in the mandatory fields',
    email: 'Email id should not be of brillio domain.',
    sameEmail: 'Entered email id already exists.',
    InvalidEmail: 'Please enter Valid Email Id',
    internalEmail: 'Entered email id should be of brillio domain',
    number: 'Mobile number should contain only numbers',
    num: 'Only Numbers accepted',
    maxLength: 'Max {#{#}#} characters is allowed.',
    minLength: 'Min {#{#}#} characters is required.',

    dateOverlap: 'Start date should not be more that end date',
  },
  statusReport: {
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Create Status Report for {#{#}#}',
    },
    summary: {
      title: 'CODEX 4.0 | Status Report',
      message: 'Status Report List Page',
    },
    summaryCreate: {
      title: 'CODEX 4.0 | Create Status Report',
      message: 'Create Status report for a Project',
    },
    nodata: {
      heading: 'No status report found',
      subtext: 'No status report has been created for the project!',
    },
    noDraftdata: {
      heading: 'No Draft reports found',
      subtext: 'No Draft report has been saved for the project!',
    },
    nodataRecepient: {
      heading: '',
      subtext: 'All Recipients Added',
    },
    success: {
      draft: 'Status Report Draft Saved Successfully.',
      submit: 'Status Report Submitted Successfully.',
      deleteSuccess: 'Draft item deleted successfully',
    },
    deleteDraft: {
      button1: 'Proceed',
      button2: 'Cancel',
      message: 'Are you sure you want to delete this draft?',
    },
    issueCreate: {
      message:
        'You’ve selected the current status of project as {#{#}#}. We will redirect you now to create it as an internal issue',
      button1Name: 'Yes, Create Internal Issue!',
      button3Name: 'No, Go back to status report',
    },
    errors: {
      dulicateFile: 'File with same name already exists',
      customerPH:
        'Customer status health can’t be changed to Red or Amber when internal project status health is selected as Green or Amber. Please change the current status of project here',
      draft: 'Some error occurred while saving Draft Status Report.',
      participents: 'At least one recipient is needed ',
      draftalert: '(Please refresh the page if the recipient is not loading)',
      submit: 'Some error occurred while submitting Status Report.',
      statusReport: 'Some error occurred while fetching status reports.',
      statusDraftReport:
        'Some error occurred while fetching draft status report.',
      customerEmails: 'Some error occurred while fetching Adhoc Pulse Mail.',
      projectConfig:
        'Some error occurred while fetching Project configuration.',
      customerFeedback: 'Some error occurred while fetching customer feedback.',
      spokes: 'Some error occurred while fetching recipient details',
      wsr: 'Some error occurred while fetching WSR details',
      download: 'Some error occurred while downloading WSR files.',
      deleteFailure: 'Failed in deleting draft item',
      prevHealthStatus:
        'Some error occurred while fetching previous project health status.',
      customerDraftEmails:
        'Some error occurred while fetching customer draft email.',
    },
    note: 'Frequency of sending Status Report to customer configured : monthly or Not agreed to send status report',
    customer:
      '"{#{#}#}" added to customer list and removed from other recipient list.',
    others:
      '"{#{#}#}" added to other recipient list and removed from customer list.',
  },
  customerEmails: {
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Create Adhoc Pulse Mail for {#{#}#}',
    },
    summary: {
      title: 'CODEX 4.0 | Adhoc Pulse Mail',
      message: 'Adhoc Pulse Mail List Page',
    },
    nodata: {
      heading: 'No Adhoc Pulse Mail found',
      subtext: 'No Adhoc Pulse Mail has been created for the project!',
    },
    summaryCreate: {
      title: 'CODEX 4.0 | Create Adhoc Pulse Mail',
      message: 'Create Adhoc Pulse Mail for a Project',
    },
    noDraftdata: {
      heading: 'No Draft reports found',
      subtext: 'No Draft report has been saved for the project!',
    },
    nodataRecepient: {
      heading: '',
      subtext: 'All Recipients Added',
    },
    success: {
      draft: 'Adhoc Pulse Mail Draft Saved Successfully.',
      submit: 'Adhoc Pulse Mail Submitted Successfully.',
    },
    errors: {
      dulicateFile: 'File with same name already exists',
      draft: 'Some error occured while saving Draft Adhoc Pulse Mail.',
      participents: 'At least one recipient is needed.',
      submit: 'Some error occured while submitting Adhoc Pulse Mail.',
      customerEmails: 'Some error occured while fetching Adhoc Pulse Mail.',
      customerFeedback: 'Some error occured while fetching customer feedback.',
      projectConfig: 'Some error occured while fetching Project configuration.',
      customerDraftEmails:
        'Some error occured while fetching customer draft email.',
      spokes: 'Some error occured while fetching receipient details',
      wsr: 'Some error occured while fetching WSR details',
      download: 'Some error occured while downloading WSR files.',
      prevHealthStatus:
        'Some error occured while fetching previous project health status.',
    },
    customer:
      '"{#{#}#}" added to customer list and removed from other recipient list.',
    others:
      '"{#{#}#}" added to other recipient list and removed from customer list.',
  },
  projectGovernance: {
    summary: {
      title: 'CODEX 4.0 | Project Governrnce',
      message: 'List of Project Governrnce',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Governrnce for {#{#}#}',
    },
    nodata: {
      heading: 'No Data Found for Project Governance',
      subtext: 'No Data found while fetching governance data ',
    },
    fetch: {
      heading: 'Project Governance Error',
      subtext:
        'Some error occured while fetching Project Governance data for {#{#}#}.',
    },
    loading: 'Please wait while we are fetching the Project Governance data...',
  },
  SurveyFeedback: {
    alreaySubmitted: "You have already submitted the feedback for this survey",
    loading: "Please wait while we are fetching the Survey Feedback data...",
    submitLoding: "Submitting your feedback...",
    title: "CODEX 4.0 | Survey Feedback",
    error: "Some error occured",
    formLabel: {
      comment: "Comment",
      pulse: "Feedback",
    }
  },
  projectConfig: {
    summary: {
      title: 'CODEX 4.0 | Project Configuration',
      message: 'List of Project Configuration',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Configuration for {#{#}#}',
    },
    nodata: {
      heading: 'Project Configuration Data not found',
      subtext: 'Project Configuration Data not found for the project!',
    },
    noChanges: {
      button1Name: 'Yes, Proceed!',
      message: `No changes done!\nDo you want to proceed as it is?`,
      button3Name: 'No, Go Back',
    },
    loading: 'Please wait while we are fetching the configuration data...',

    success: {
      create: 'Project Configuration submitted successfully!',
      update: 'Project Configuration updated successfully!',
      close: 'Project Configuration closed successfully!',
    },
    fetch: {
      heading: 'Project Configuratoin Error',
      subtext:
        'Some error occured while fetching configuration data for {#{#}#}.',
    },
    errors: {
      create: 'Some error occured while creating CodeX Project.',
      update: 'Some error occured while updating Project Configuration.',
      close: 'Some error occured while closing Project Configuration.',
    },
    fileError: {
      message: 'File type must be PNG',
      button1Name: 'Ok! Got it.',
    }
  },
  stakeholder: {
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Create Stakeholder Report for {#{#}#}',
    },
    summary: {
      title: 'CODEX 4.0 | Stakeholder',
      message: 'Stkaholder List Page',
    },
    success: {
      create: 'Stakeholder created successfully!',
      update: 'Stakeholder updated successfully!',
      delete: 'Stakeholder deleted successfully!',
    },
    errors: {
      stakeholder: 'Some error while fetching Stakeholders',
      create: 'Some error occured while creating Stakeholder.',
      update: 'Some error occured while updating Stakeholder.',
      delete: 'Some error occured while deleting Stakeholder.',
    },
    errorsNS: {
      stakeholder: 'Some error while fetching NetSuite Stakeholders',
    },
    errorData: {
      button1Name: 'Client Stakeholder',
      button2Name: 'Internal Stakeholder',
      message: 'What type of stakeholder would you like to add?',
    },
    noChanges: {
      button1Name: 'Yes, Proceed!',
      message: `No changes done!\nDo you want to proceed as it is?`,
      button3Name: 'No, Go Back',
    },
    confirm: {
      button1Name: 'Yes, Proceed!',
      message: 'Are you sure you want to delete the selected Stakeholder?',
      button3Name: 'No, Go Back',
    },
    nodataInternal: {
      heading: 'No Internal Stakeholders Found',
      subtext: 'Please add internal stakeholder to appear here.',
    },
    nodataClient: {
      heading: 'No Client Stakeholders Found',
      subtext: 'Please add client stakeholder to appear here.',
    },
    alert: {
      inactive: 'This email Id no longer exists.',
    },
    netSuite: {
      source: 'Source : NetSuite',
    },
  },
  dependency: {
    summary: {
      title: 'CODEX 4.0 | Dependency',
      message: 'List of all Dependency',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Dependency for {#{#}#}',
    },
    nochanges: `No changes done!\nDo you want to proceed as it is?`,
    nochangesBtn1: 'Yes, Proceed!',
    nochangesBtn2: 'No, Go Back',
    success: {
      create: 'Dependency created successfully!',
      update: 'Dependency updated successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Dependency.',
      update: 'Some error occurred while updating Dependency.',
      getActions: 'Some error occurred while fetching actions for Dependency.',
      fetchRisk: 'Some error occurred while fetching risk for dependency.',
      fetchIssues: 'Some error occurred while fetching issues for dependency.',
    },
  },

  Assessment: {
    summary: {
      title: 'CODEX 4.0 | HOME | Project Self-Assessment',
      message: "List of project self-assessment and it's details",
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Self-Assessment for {#{#}#}',
    },
    success: {
      create: 'Assessment created successfully!',
      save: 'Draft Assessment saved successfully!',
      update: 'Assessment updated successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Assessment.',
      save: 'Some error occurred while saving Draft Assessment.',
      update: 'Some error occurred while updating Assessment.',
      copy: 'Some error occurred while copying  previous assessment.',
      fetchAssessDetails:
        'Some error occurred while fetching Assessment Details.',
    },

    noAssessmentData: {
      heading: 'No Project Self-Assessment Found',
      subtext: 'Please Add Project Self-Assessment to appear here.',
    },
    noDraftData: {
      heading: 'No Saved Assessment Found',
      subtext: 'Please Save Assessment to appear here.',
    },

    button1Name: 'Copy of previous assessment',
    button2Name: 'New Assessment',
    message:
      'Do you want to create a new assessment or copy from previous assessment?',
    loading:
      'Please wait while we are fetching the Project Self-Assessment data...',
    nochanges: `No changes done!`,
    nochangesBtn1: ' Go Back',
  },

  CxSurvey: {
    summary: {
      title: 'CODEX 4.0 | HOME | Customer Survey',
      message: "List of customer survey and it's details",
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Customer Survey for {#{#}#}',
    },
    summaryCreate: {
      title: 'CODEX 4.0 | Create CX Survey',
      message: 'Create Cx Survey for a Project',
    },
    success: {
      create: 'Customer Survey created successfully!',
      update: 'Customer Survey updated successfully!',
      save: 'Draft Survey saved successfully!',
    },
    errors: {
      create: 'Some error occurred while creating Customer Survey.',
      update: 'Some error occurred while updating Customer Survey.',
      save: 'Some error occurred while while saving this Draft Customer Survey.',
      fetchSurveyDetails:
        'Some error occurred while fetching Customer Survey Details.',
    },
    noSurveyData: {
      heading: 'No Customer Survey Found',
      subtext: 'Please Add Customer Survey to appear here.',
    },
    noDraftData: {
      heading: 'No Saved Customer Survey Found',
      subtext: 'Please Save Customer Survey to appear here.',
    },
    surveyTitleError: 'Please enter the survey title.',
    viewSectionEmptyData: {
      heading: 'No question selected in this section',
      subtext: 'Navigate to other sections',
    }, 
    viewEmptyData: 'No question selected in this section',
    questionError:
      'Please add minimum of five questions to maximum of ten questions.',
    stakeholderError: 'Please select at least one stakeholder.',
    emailContentError: 'Please enter less than 5000 characters.',
    formError: 'Please fill the details in step 1 and step 2',
    button1Name: 'New Survey',
    button2Name: 'Edit Survey',
    loading: 'Please wait while we are fetching the Customer Survey data...',
    nochanges: `No changes done!`,
    nochangesBtn1: ' Go Back',
    emailSubject: 'Feedback for {#{#}#}',
    emailContent:
      '<p>Your feedback matters! We invite you to participate in this feedback to help us enhance our services and improve customer experiences. It will take around 10 minutes of your time.&nbsp;</p><p><br></p><p>Your responses are confidential, and your input is valuable to us. Thank you for being a part of Brillio.&nbsp;</p>',
  },

  projecthealth: {
    summary: {
      title: 'CODEX 4.0 | Project Health',
      message: 'Project Health List Page',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Health for {#{#}#}',
    },
    success: {
      create: 'Project Health created successfully!',
      update: 'Project Health updated successfully!',
      proactive: 'Proactive Project Health updated successfully!',
    },
    errors: {
      create: 'Some error occured while creating Project Health.',
      update: 'Some error occured while updating Project Health.',
    },
    openError: {
      button1Name: 'Close',
      message:
        'Please close all open Escalations, Issues or Client Pulses to update project health.',
    },
  },
  projecthealthhistory: {
    summary: {
      title: 'CODEX 4.0 | Project Health History',
      message: 'Project Health History List Page',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Health History for {#{#}#}',
    },
    success: {
      create: 'Project Health History created successfully!',
      update: 'Project Health History updated successfully!',
    },
    errors: {
      create: 'Some error occured while creating Project Health History.',
      update: 'Some error occured while updating Project Health History.',
    },
  },
  filter: {
    nodata: {
      heading: '',
      subtext: 'No Filters found for selected section.',
    },
    form: {
      required: 'Please fill in the mandatory fields',
      maxLength: 'Max {#{#}#} characters is allowed.',
    },
  },
  ipp: {
    summary: {
      title: 'CODEX 4.0 | Integrated Project Plan',
      message: 'Integrated Project Plan',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Integrated Project Plan for {#{#}#}',
    },
    elegibleErr: {
      button1Name: 'Go Back',
      message: 'Please submit Project configuration and Project Information',
    },
    success: {
      create: 'Integrated Project Plan Review Send Successfully!',
      close: 'Failed in Sending Review!',
    },
    nodataComment: {
      heading: 'No Comments Yet!',
      subtext: 'Be the 1st one to Comment.',
    },
    nodataCommentDELA: {
      heading: 'No Comments Yet!',
      subtext: '',
    },
    reviewer: {
      success: 'Reviewer added successfully!',
      error: 'Some error while adding Reviewer',
    },
  },
  clientPulse: {
    summary: {
      title: 'CODEX 4.0 | Client Pulse',
      message: 'List of Client Pulse',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Client Pulse for {#{#}#}',
    },
    success: {
      get: 'Fetched the Client Pulse History successfully',
    },
    error: {
      get: 'Some error in fetching Client Pulse history',
    },
    nodata: {
      heading: 'No Client Pulse Found',
      subtext: 'No Client Pulse Found for the selected filter.',
    },
    nodataClientPulse: {
      heading: 'No Client Pulse Found',
      subtext: 'No Client Pulse found for {#{#}#}',
    },
    errorGET: {
      heading: 'Error Occured!',
      subtext:
        'Some error occured while fethcing client pulse data. Please try agian.',
    },
  },
  riskProfile: {
    summary: {
      title: 'CODEX 4.0 | Risk Profile',
      message: 'Risk Profile',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Risk Profile for {#{#}#}',
    },
    loading: 'Please wait while we are fetching the Risk Profile data...',
    fetch: {
      heading: 'Risk Profile Error',
      subtext:
        'Some error occured while fetching Risk Profile data for {#{#}#}.',
    },
    success: {
      create: 'Risk Profile submitted successfully!',
      update: 'Risk Profile updated successfully!',
    },
    errors: {
      create: 'Some error occured while creating Risk Profile',
      update: 'Some error occured while updating Risk Profile',
    },
  },
  telemetry: {
    summary: {
      title: 'CODEX 4.0 | Telemetry',
      message: 'List of Telemetry',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Telemetry for {#{#}#}',
    },
    tokenError: {
      heading: 'Telemetry Page Error',
      subtext: 'Some error occured while fethcing the access token.',
    },
    noData: {
      heading: 'No Telemetry Data Found',
      subtext: 'No Telemetry Data found for {#{#}#}',
    },
  },

  projectAssets: {
    summary: {
      title: 'CODEX 4.0 | Project Assets',
      message: 'Project Assets',
    },
    project: {
      title: 'CODEX 4.0 | {#{#}#}',
      message: 'Project Assets for {#{#}#}',
    },

    loading: 'Please wait while we are fetching the Project Assets data...',
    fetch: {
      heading: 'Project Assets Error',
      subtext:
        'Some error occured while fetching Project Assets data for {#{#}#}.',
    },
    fileError: {
      message: 'File type must be PNG',
      button1Name: 'Ok! Got it.',
    },
    sameFileError: {
      message: '{#{#}#} is already exist.',
      button1Name: 'Ok! Got it.',
    },
    deleteError: {
      button1Name: 'Yes! Delete',
      message: 'Are you sure you want to delete the selected assest?',
      button3Name: 'Cancel',
    },
    success: {
      create: 'Project Assets Uploaded Successfully',
      delete: 'Project Assets Deleted Successfully',
    },
    errors: {
      create: 'Some error occurred while Uploading Project Assets.',
      download: 'Some error occurred while downloading Project Asset files.',
      delete: 'Some error occured while deleting Project Asset files.',
    },
    nodata: {
      heading: 'No Data Found for Project Asset',
      subtext: 'No Data found while fetching Asset data ',
    },
    button1Name: 'Cancel',
    button2Name: 'Yes',
    message: 'Are you sure you want to delete this Assets ?',
  },
  common: {
    fileExtError: 'File extension is not allowed',
    fileTypeError: 'File type is not allowed',
    fileNameTooLong: 'File name is too long',
    fileSizeLimit: 'File size is not within the allowed limits',
    fileNameError: 'File name contains unsupported characters',
    fileRestrictionTitle: 'NOTE: Following file restrictions will apply.'
  }
};
