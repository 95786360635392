import {FilterData, FilterItem} from 'src/app/app.models';
import {Page, pages} from 'src/app/app.pages';
import {CodexFeatureRoleData} from 'src/app/auth/store';
import {dismissHandler, getValueStr} from 'src/Utils';
import {Project} from '../../my-projects/store';
import {actionSource, actionStatus} from './actions.constants';
import * as models from './actions.model';

export const quickFilterCount = (
  data: models.ActionData[],
  quickFilters: FilterData[],
) => {
  let ref: {[s: string]: number} = {};
  data.forEach(item => {
    // Filter Logic for Open Actions
    if (!ref['openActions'] && (item.status === 0 || item.status === 1)) {
      ref['openActions'] = 1;
    } else if (item.status === 0 || item.status === 1) {
      ref['openActions'] += 1;
    }
    // Filter Logic for Project Level Actions
    if (!ref['projectLevelActions'] && item.level === 1) {
      ref['projectLevelActions'] = 1;
    } else if (item.level === 1) {
      ref['projectLevelActions'] += 1;
    }
    // Filter Logic for Account Level Actions
    if (!ref['accountLevelActions'] && item.level === 2) {
      ref['accountLevelActions'] = 1;
    } else if (item.level === 2) {
      ref['accountLevelActions'] += 1;
    }
    // Filter form Escalation Actions
    if (!ref['escActions'] && item.actionsource === 1) {
      ref['escActions'] = 1;
    } else if (item.actionsource === 1) {
      ref['escActions'] += 1;
    }
    // Filter for Governance Actions
    // if (!ref['govActions'] && item.actionsource === 7) {
    //   ref['govActions'] = 1;
    // } else if (item.actionsource === 7) {
    //   ref['govActions'] += 1;
    // }
    //Filter for Risk Actions
    if (!ref['riskActions'] && item.actionsource === 2) {
      ref['riskActions'] = 1;
    } else if (item.actionsource === 2) {
      ref['riskActions'] += 1;
    }
    //Filter for Project Actions
    // if (!ref['projectActions'] && item.actionsource === 3) {
    //   ref['projectActions'] = 1;
    // } else if (item.actionsource === 3) {
    //   ref['projectActions'] += 1;
    // }
    //Filter for CSAT actions
    if (!ref['CSATActions'] && item.actionType === 'CSAT improvements') {
      ref['CSATActions'] = 1;    
    } else if (item.actionType === 'CSAT improvements') {
      ref['CSATActions'] += 1;
    }
    //Filter for RightStart actions
    if (!ref['RightStartActions'] && item.actionType === 'RightStart') {
      ref['RightStartActions'] = 1;
    } else if (item.actionType === 'RightStart') {
      ref['RightStartActions'] += 1;
    }
  });

  return quickFilters.map(f => {
    switch (f.id) {
      case 2:
        return {...f, count: ref['openActions'] || 0};
      case 3:
        return {...f, count: ref['escActions'] || 0};
      // case 4:
      //   return {...f, count: ref['govActions'] || 0};
      case 5:
        return {...f, count: ref['riskActions'] || 0};
      // case 6:
      //   return {...f, count: ref['projectActions'] || 0};
      case 7:
        return {...f, count: ref['projectLevelActions'] || 0};
      case 8:
        return {...f, count: ref['accountLevelActions'] || 0};
      case 4:
        return {...f, count: ref['CSATActions'] || 0};
      case 9:
        return {...f, count: ref['RightStartActions'] || 0};
      default:
        return {...f, count: data.length};
    }
  });
};

export const getSource = (projects: models.ActionData[]): FilterItem[] => {
  let ref: {[s: number]: number} = {};
  projects.forEach(p => {
    if (!p.actionsource) return;
    if (!ref[p.actionsource]) {
      ref[p.actionsource] = 1;
    } else ref[p.actionsource] += 1;
  });
  return Object.entries(ref).map(([key, value]) => {
    return {
      id: +key,
      isChecked: false,
      value: actionSource[+key],
      count: value,
    };
  });
};

export const getAccounts = (projects: models.ActionData[]) => {
  let ref = {};
  const accounts: FilterItem[] = [];
  projects.forEach((p, i) => {
    if (!p.accountname) return;
    if (!ref[p.accountname]) {
      accounts.push({id: i + 1, isChecked: false, value: p.accountname});
      ref[p.accountname] = true;
    }
  });
  return accounts;
};

export const getAssigedTo = (projects: models.ActionData[]) => {
  let ref = {};
  const assignedTo: FilterItem[] = [];
  projects.forEach((p, i) => {
    const baseName = p.assignedtoname || 'Blank';
    const name = baseName + '-assigned';
    if (!ref[name]) {
      assignedTo.push({id: i + 1, isChecked: false, value: name});
      ref[name] = true;
    }
  });
  return assignedTo;
};

export const getRaisedBy = (projects: models.ActionData[]) => {
  let ref = {};
  const raisedBy: FilterItem[] = [];
  projects.forEach((p, i) => {
    const name = p.raisedby + '-raised';
    if (!ref[name]) {
      raisedBy.push({id: i + 1, isChecked: false, value: name});
      ref[name] = true;
    }
  });
  return raisedBy;
};

export const getStatus = (projects: models.ActionData[]): FilterItem[] => {
  let ref: {[s: number]: number} = {};
  projects.forEach((p, i) => {
    if (!ref[p.status]) {
      ref[p.status] = 1;
    } else ref[p.status] += 1;
  });
  return Object.entries(ref).map(([key, value]) => {
    return {
      id: +key,
      isChecked: false,
      value: actionStatus[+key],
      count: value,
    };
  });
};

export const checkLevel = (p: models.ActionData) => {
  if (p.level === 1) {
    return 'Project Level';
  }
  if (p.level === 2) {
    return 'Account Level';
  }
  return 'Unknown Type';
};

export const checkType = (p: models.ActionData) => {
  if (p.actionType) return p.actionType;
  return null;
};

export const getLevel = (projects: models.ActionData[]): FilterItem[] => {
  let ref: {[s: number]: number} = {};
  projects.forEach((p, i) => {
    // For Project Level
    if (checkLevel(p) === 'Project Level' && !ref['Project Level']) {
      ref['Project Level'] = 1;
    } else if (checkLevel(p) === 'Project Level' && ref['Project Level']) {
      ref['Project Level'] += 1;
    }
    // For Account Level
    if (checkLevel(p) === 'Account Level' && !ref['Account Level']) {
      ref['Account Level'] = 1;
    } else if (checkLevel(p) === 'Account Level' && ref['Account Level']) {
      ref['Account Level'] += 1;
    }
  });
  return Object.entries(ref).map(([key, value], index) => {
    return {id: index, isChecked: false, value: key, count: value};
  });
};

export const getType = (projects: models.ActionData[]) => {
  let ref = {};
  const type: FilterItem[] = [];
  projects.forEach((p, i) => {
    if (!p.actionType) return;
    if (!ref[p.actionType]) {
      type.push({id: i + 1, isChecked: false, value: p.actionType});
      ref[p.actionType] = true;
    }
  });
  return type;
};

export const getFilterChecklist = (state: models.ActionsState) => {
  let checkList: string[] = [];
  const sourceStr = getValueStr(state.source, ',');
  if (sourceStr) checkList.push(sourceStr);
  const statusStr = getValueStr(state.status, ',');
  if (statusStr) checkList.push(statusStr);
  const accountStr = getValueStr(state.accounts);
  if (accountStr) checkList.push(accountStr);
  const assignedToStr = getValueStr(state.assignedTo, ',');
  if (assignedToStr) checkList.push(assignedToStr);
  const raisedByStr = getValueStr(state.raisedBy, ',');
  if (raisedByStr) checkList.push(raisedByStr);
  const levelStr = getValueStr(state.level, ',');
  if (levelStr) checkList.push(levelStr);
  const typeStr = getValueStr(state.type, ',');
  if (typeStr) checkList.push(typeStr);
  return checkList;
};

export const sort = (list: models.ActionData[], sort: FilterItem) => {
  switch (sort?.id) {
    case 1:
      return list.sort((a, b) => {
        return a.actionname.localeCompare(b.actionname);
      });
    case 2:
      return list.sort((a, b) => a.createddate - b.createddate);
    case 3:
      return list.sort((a, b) => b.createddate - a.createddate);
    default:
      return list;
  }
};

export const handleQuickFilter = (data: models.ActionData[], id: number) => {
  return data.filter(item => {
    if (id === 2) return item.status === 0 || item.status === 1;
    if (id === 3) return item.actionsource === 1;
    if (id === 4) return item.actionType === 'CSAT improvements';
    if (id === 5) return item.actionsource === 2;
    // if (id === 6) return item.actionsource === 3;
    if (id === 7) return item.level === 1;
    if (id === 8) return item.level === 2;
    if (id === 9) return item.actionType === 'RightStart';
    return true;
  });
};

export const handleCsatYearFilter = (data: models.ActionData[], year: string) => {
  return data.filter(item => {
    if (item.actionType === 'CSAT improvements' && (year === '--All--' || (item.csatYear && item.csatYear.toString() === year))) {
      return true;
    }
    return false;
  });
};

export const dismissFilter = (
  state: models.ActionsState,
  ref: {[s: string]: string},
): models.ActionsState => {
  const updatedAccount = dismissHandler(state.accounts, ref);
  const accCount = updatedAccount.filter(a => a.isChecked);
  const updatedStatus = dismissHandler(state.status, ref);
  const statusCount = updatedStatus.filter(a => a.isChecked);
  const updatedSource = dismissHandler(state.source, ref);
  const sourceCount = updatedSource.filter(a => a.isChecked);
  const updatedAssignedTo = dismissHandler(state.assignedTo, ref);
  const assignedToCount = updatedAssignedTo.filter(a => a.isChecked);
  const updatedRaisedBy = dismissHandler(state.raisedBy, ref);
  const raisedByCount = updatedRaisedBy.filter(a => a.isChecked);
  const updatedLevel = dismissHandler(state.level, ref);
  const levelCount = updatedLevel.filter(a => a.isChecked);
  const updatedType = dismissHandler(state.type, ref);
  const typeCount = updatedType.filter(a => a.isChecked);
  return {
    ...state,
    accounts: updatedAccount,
    status: updatedStatus,
    source: updatedSource,
    assignedTo: updatedAssignedTo,
    raisedBy: updatedRaisedBy,
    level: updatedLevel,
    type: updatedType,
    filters: state.filters.map(f => {
      if (f.id === 1) return {...f, count: accCount.length};
      if (f.id === 2) return {...f, count: statusCount.length};
      if (f.id === 3) return {...f, count: sourceCount.length};
      if (f.id === 4) return {...f, count: assignedToCount.length};
      if (f.id === 5) return {...f, count: raisedByCount.length};
      if (f.id === 6) return {...f, count: levelCount.length};
      if (f.id === 7) return {...f, count: typeCount.length};
      return f;
    }),
  };
};

export const getPaths = (source: number) => {
  const init = [pages.MY_PROJECTS, pages.PROJECT_DETAILS];
  if (source === 1) return [...init, pages.ESCALATION, pages.ACTIONS];
  if (source === 2) return [...init, pages.RISK_MANAGEMENT, pages.ACTIONS];
  if (source === 5) return [...init, pages.INTERNAL_ISSUE, pages.ACTIONS];
  if (source === 8) return [...init, pages.ASSESSMENT, pages.ACTIONS];
  if (source === 11) return [...init, pages.DEPENDENCY, pages.ACTIONS];
  if (source === 12) return [...init, pages.ASSUMPTION, pages.ACTIONS];
  return [...init, pages.ACTIONS];
};

export const getAccPaths = (source: number) => {
  const init = [pages.MY_ACCOUNTS, pages.ACCOUNT_DETAILS];
  if (source === 1) return [...init, pages.ESCALATION, pages.ACTIONS];
  if (source === 2) return [...init, pages.RISK_MANAGEMENT, pages.ACTIONS];
  if (source === 5) return [...init, pages.INTERNAL_ISSUE, pages.ACTIONS];
  if (source === 8) return [...init, pages.ASSESSMENT, pages.ACTIONS];
  if (source === 11) return [...init, pages.DEPENDENCY, pages.ACTIONS];
  if (source === 12) return [...init, pages.ASSUMPTION, pages.ACTIONS];
  return [...init, pages.ACTIONS];
};

export const removePath = (paths: Page[]) => {
  if (paths.length <= 2) return paths;
  return [pages.MY_PROJECTS, pages.ACTIONS];
};

export const toggleCheckAll = (data: models.ActionData[], payload: boolean) => {
  return data.map(item => {
    if (item.status === 2) return item;
    return {...item, isChecked: payload};
  });
};

export const toggleCheckAction = (
  data: models.ActionData[],
  payload: string,
) => {
  return data.map(item => {
    if (item.actionid !== payload) return item;
    return {...item, isChecked: !item.isChecked};
  });
};

export const codexIdCheck = (
  payload: models.CreateActionPayload | models.ActionData,
  project: Project,
) => {
  if (payload.level === 2) return null;
  if (payload.codexgroupid == null) return project.codexId;
  else {
    return payload.codexgroupid;
  }
};

export const getLAorDERole = (arr: CodexFeatureRoleData[]) => {
  const LAorDECodexIds = arr
    .filter(item =>
      item.rolefeaturelist.some(
        role => role.roleId === 8 || role.roleId === 11,
      ),
    )
    .map(item => item.codexId);
  return LAorDECodexIds;
};

export const projectNameCheck = (
  payload: models.CreateActionPayload | models.ActionData,
  project: Project,
) => {
  if (payload.level === 2) return null;
  if (payload.projectname == null) return project.projectName;
  else {
    return payload.projectname;
  }
};

export const accountNameCheck = (
  payload: models.CreateActionPayload | models.ActionData,
  project: Project,
) => {
  if (payload.accountname !== null) return payload.accountname;
  return project.accountName;
};
